import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    inject,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ItmUserService } from '@itm/services';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { PushNotificationSignalRService } from 'app/modules/admin/shared/services/push.notification.signalr.service';
import { Subject, takeUntil } from 'rxjs';
import { NotificationsService } from '../notifications/notifications.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    exportAs: 'user',
    standalone: true,
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        NgClass,
        MatDividerModule,
    ],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = false;
    user: User;
    private readonly _itmUserService = inject(ItmUserService);
    private readonly msalService = inject(MsalService);
    private readonly _pushNotificationSignalRService = inject(PushNotificationSignalRService);
    // userSig = computed(()=>{
    //     // console.log('UserComponent - userSig')
    //     return this._itmUserService.userSignal();
    // });
    profilePhoto:string = `https://placehold.co/300/f86704/FFF`;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
      private readonly notificationsService = inject(NotificationsService);

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
    ) {
        // console.log('UserComponent - constructor')
        effect(()=>{
            // console.log('userSig',this.userSig());
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // console.log('UserComponent... ngOnInit()')

        this._itmUserService.decodeJwt_SetUser();
        this._itmUserService.user$.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => {
                
                this.user = user;

                this._pushNotificationSignalRService.startConnection();
        
                this.notificationsService.getAll().subscribe(res => {
                    // console.log(res)
                });
            });


        // Subscribe to user changes
        // this._userService.user$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((user: User) => {
        //         this.user = user;

        //         // Mark for check
        //         this._changeDetectorRef.markForCheck();
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService
            .update({
                ...this.user,
                status,
            })
            .subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this.msalService.logoutRedirect();
    }
}
