import { inject, Injectable } from "@angular/core";
import { ApiHttpService } from "@itm/services";

@Injectable({ providedIn: 'root' })
export class CacActionService {
    private readonly http = inject(ApiHttpService);

    constructor() { }
    
    getActionById<T>(id: string){
        const url = `cac/action/${id}`;
        return this.http.GETT<T>(url);
    }
    
    doNotEscalate<T>(data: any){
        const url = `cac/action/donotescalate`;
        return this.http.POST<T>(url, data);
    }
    
    remind<T>(data: any){
        const url = `cac/action/remind`;
        return this.http.POST<T>(url, data);
    }
    
    archive<T>(data: any){
        const url = `cac/action/archive`;
        return this.http.POST<T>(url, data);
    }
}