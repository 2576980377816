import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, computed, effect, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule, ClickEventArgs, ToolbarAllModule, MenuModule } from '@syncfusion/ej2-angular-navigations';
import { ToolbarButton, ToolbarOverflowMode, ToolbarItemType } from '@itm/types';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonAllModule, SwitchComponent, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbsComponent } from '@itm/components/breadcrumbs'
import { LayoutService } from '@itm/services';
@Component({
  selector: 'itm-toolbar',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
  // encapsulation: ViewEncapsulation.None,
  imports: [CommonModule,
    MatIconModule,
    ToolbarModule,
    DropDownListModule,
    ToolbarAllModule,
    MenuModule,
    ButtonAllModule,
    DropDownButtonModule,
    BreadcrumbsComponent,
    SwitchModule
  ]
})
export class ItmToolbarComponent {
  public itmToolbarAction: any = { 'class': 'itm-toolbar-action' };
  //@Output() toolbarButtonClick: EventEmitter<any> = new EventEmitter<any>();
  ///events
  // toolbarButtonClick = output<any>();

  ///constants
  TOOL_BAR_ITEM_TYPE_BUTTON: ToolbarItemType = 'Button';
  TOOL_BAR_ITEM_TYPE_SWITCH: ToolbarItemType = 'Switch';
  TOOL_BAR_ITEM_TYPE_SEPARATOR: ToolbarItemType = 'Separator';
  TOOL_BAR_ITEM_TYPE_DROPDOWN_BUTTON: ToolbarItemType = 'DropdownButton';
  TOOL_BAR_TYPE_FORM: string = 'form';
  TOOL_BAR_TYPE_LIST: string = 'list';

  ///inputs
  breadcrumb = input<string[]>();
  overflowMode = input<ToolbarOverflowMode>();
  toolbarType = input.required<string>();
  buttons = input.required<ToolbarButton[]>();
  editTitle = input<string>();
  buttonsSorted = computed(() => {
    return this.buttons().sort((a, b) => {
      // Handle null or undefined values
      if (a.order == null && b.order == null) return 0; // Both are null or undefined
      if (a.order == null) return -1; // a is null or undefined, comes before b
      if (b.order == null) return 1; // b is null or undefined, comes before a

      // Compare numeric orders
      return a.order - b.order;
    });
  });

  private readonly _ls = inject(LayoutService);

  constructor(){
    effect(() =>{
      // console.log(this.toolbarType())
    });
  }

  ngOnInit(): void {
    // console.log(this.toolbarType())
  }

  public toolbarClick(args: ClickEventArgs): void { 
    const buttonText = args.item.id;
    if (buttonText != '') {
      this._ls.setToolbarButtonClick(buttonText);
    }
  }

  public toolbarFormClick(args: ClickEventArgs): void {
    const buttonText = args.item.text;
    if (buttonText != '') {
      this._ls.setToolbarFormButtonClick(buttonText);
    }
  }

  onExportClick(args: any) {
    const exportType = args.element.innerText;
    if (exportType != '') {
      this._ls.setToolbarButtonClick(exportType);
    }
  }

  switchState: boolean = true;
  onSwitchChange(name: any, event: any): void {
    this.switchState = event.checked;
    // console.log(this.switchState)
    this.buttons().map((m: any) => {
      if(['DoNotEscalate', 'Remind', 'Archive', 'Export'].includes(m.name))
        m.visible = this.switchState;
    });
    this._ls.setToolbarSwitchClick({name, checked: this.switchState});
    // Handle the switch state as needed
  }
}
