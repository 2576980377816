;
(function (root, factory, undef) {
  if (typeof exports === "object") {
    // CommonJS
    module.exports = exports = factory(require("./core"), require("./sha256"), require("./hmac"));
  } else if (typeof define === "function" && define.amd) {
    // AMD
    define(["./core", "./sha256", "./hmac"], factory);
  } else {
    // Global (browser)
    factory(root.CryptoJS);
  }
})(this, function (CryptoJS) {
  return CryptoJS.HmacSHA256;
});