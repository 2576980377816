export interface ToolbarButton {
    align?: string;
    click?: any;
    cssClass?: string;
    disabled?: boolean;
    height?: string;
    htmlAttributes?: string;
    id?: string;
    overflow?: string;
    order?: number;
    prefixIcon?: string;
    showAlwaysInPopup?: boolean;
    showTextOn?: string;
    suffixIcon?: string;
    tabIndex?: string;
    caption?: string;
    template?: string;
    tooltipText?: string;
    type?: string;
    visible?: boolean;
    switchButton?: boolean;
    width?: string;
}
export type ToolbarOverflowMode = 'Scrollable' | 'Popup' | 'Extended' | 'MultiRow';
export type ToolbarType = 'form' | 'list';

export class ActionName {
    public static New = "New";
    public static Delete = "Delete";
    public static Close = "Close";
    public static Refresh = "Refresh";
    public static Save = "Save";
    public static Edit = "Edit";
    public static SaveAndNew = "Save And New";
    public static SaveAndClose = "Save And Close";
    public static Export = "Export";
    public static Expand = "Expand";
    public static Collapse = "Collapse";
    public static SaveAs = "Save as";
    public static SaveState = "Save State";
    public static SendSms = "Send Sms";
    public static SendEmail = "Send Email";
    public static Scan = "Scan";
    public static UploadResult = "UploadResult";
    public static DoNotEscalate = "DoNotEscalate";
    public static Remind = "Remind";
    public static Archive = "Archive";
}
export class ActionItem {
    public static Toolbar = "Toolbar";
}
export class ToolbarItemOption {
    icon: string;
    text: string;
    click: any;
}
export type ToolbarItemType = 'Button' | 'Separator' | 'DropdownButton' | 'Switch';

// export const ListToolbarItems: ToolbarButton[] = [
//     { order: 4, type: 'Button', id: 'New', caption: 'New', align: 'Right', prefixIcon: 'e-expand' },
//     { order: 5, type: 'DropdownButton', id: 'Export', caption: 'Export', align: 'Right', prefixIcon: 'e-icons e-export' },
//     { order: 6, type: 'Button', id: 'Close', caption: 'Close', align: 'Right', prefixIcon: 'e-close' },
// ];
export const FormToolbarItems: ToolbarButton[] = [
    { order: 4, type: 'Button', id: 'Save', caption: 'Save', align: 'Right', prefixIcon: 'e-save' },
    { order: 5, type: 'Button', id: 'SaveAndNew', caption: 'Save & New', align: 'Right', prefixIcon: 'e-save' },
    { order: 6, type: 'Separator' },
    { order: 7, type: 'Button', id: 'SaveAndClose', caption: 'Save & Close', align: 'Right', prefixIcon: 'e-save' },
    { order: 8, type: 'Button', id: 'Close', caption: 'Close', align: 'Right', prefixIcon: 'e-close' },
];