import { inject, Injectable } from '@angular/core';
import { ApiHttpService } from '@itm/services';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-kanban'
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ActionListService extends Subject<DataStateChangeEventArgs> {
    private readonly http = inject(ApiHttpService);
    
    constructor() {
        super();
    }

    public execute(branchId: any, state: any): void {
        const url = `centre?branchId=${branchId}`;
        this.getData(url, state).subscribe(x => super.next(x));
    }

    protected getData(url: any, state: DataStateChangeEventArgs): Observable<DataStateChangeEventArgs> {
        return this.http.POST<any>(url, state)
           .pipe(map((response: any) => {
                // console.log(response.data.result)
                return response.data.result;
           }))
           .pipe(map((response: any) => (<any>{
                result: response
            })))
            .pipe((data: any) => data);
    }
}