@if (breadcrumb() && breadcrumb().length>0) {
<div class="flex-1 min-w-0">
    <div class="flex flex-wrap items-center font-medium">
        <div>
            <a class="whitespace-nowrap breadcrumb-item title">{{firstItem()}}</a>
        </div>
        @for (item of breadcrumb(); track item; let i = $index, last=$last) {
        @if (i > 0 && !last) {
        <div class="flex items-center ml-1 whitespace-nowrap">
            <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
            <a class="ml-1 breadcrumb-item">{{item}}</a>
        </div>
        }
        }
        @if (lastItem()) {
        <div class="flex items-center ml-1 whitespace-nowrap">
            <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
            <span class="ml-1 text-secondary breadcrumb-item">{{lastItem()}}</span>
        </div>
        }
        @if (editTitle()) {
            <div class="flex items-center ml-1 whitespace-nowrap">
                <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
                <span class="ml-1 text-secondary breadcrumb-item">{{editTitle()}}</span>
            </div>
        }
    </div>
</div>
}