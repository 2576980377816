import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, HostListener, inject, input, Input, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ItmToolbarComponent } from '@itm/components/toolbar';
import { ApiHttpService, ItmToastrService, ItmUserService, LayoutService } from '@itm/services';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { 
  GridModule, SearchService, ToolbarService, ExcelExportService, PdfExportService, FilterService, SortService, GroupService, PageService
  , ColumnChooserService, ColumnMenuService, ReorderService, ResizeService
} from '@syncfusion/ej2-angular-grids';
import { CardSettingsModel, DataStateChangeEventArgs, DialogSettingsModel, DragEventArgs, KanbanComponent, KanbanModule, SwimlaneSettingsModel, ActionEventArgs } 
from '@syncfusion/ej2-angular-kanban'
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { CacActionService } from 'app/modules/admin/cac/cac.action.service';
import { map, Observable, Subject } from 'rxjs';
import { SideBarManagerComponent } from '../popup/manager/sidebar-manager.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { ActionStatus } from '../utilities';
import { CacNotifyService } from '../../shared/services/cac.notify.service';
import { ActionListService } from '../action.list.service';

@Component({
    selector: 'action-centre-kanban',
    templateUrl: './action-centre-kanban.component.html',
    styleUrls: ['./action-centre-kanban.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.Default,
    imports: [RouterOutlet, CommonModule, GridModule, ItmToolbarComponent, FuseAlertComponent, MatInputModule, FuseDrawerComponent
      , DropDownListModule, SidebarModule, SideBarManagerComponent, KanbanModule],
    providers: [SearchService,
      ToolbarService,
      ExcelExportService,
      PdfExportService,
      FilterService,
      SortService,
      GroupService,
      PageService,
      ColumnChooserService,
      ColumnMenuService,
      ReorderService,
      ResizeService,
      ActionListService
    ],
})
export class ActionCentreKanbanComponent implements OnInit, OnDestroy {
  @ViewChild('kanbanctrl', { static: false }) kanbanctrl?: KanbanComponent;
  
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private readonly _layoutService = inject(LayoutService);
  private readonly _cacService = inject(CacActionService);
  private readonly _toastr = inject(ItmToastrService);
  private readonly _fuseConfirmationService = inject(FuseConfirmationService);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _titleService = inject(Title);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  private readonly _itmUserService = inject(ItmUserService);
  private readonly _http = inject(ApiHttpService);
  private readonly _cacNotifyService = inject(CacNotifyService);
  private readonly _router = inject(Router);


  new: any = '';
  public dataManager: DataManager;
  parentEmbeddedAppTypeId: any = 0

  public data: Observable<DataStateChangeEventArgs>;

  ActionStatus = ActionStatus;
  public cardSettings: CardSettingsModel = {
      headerField: 'id',
      showHeader: true,
      selectionType: 'Single'
  };
  public swimlaneSettings: SwimlaneSettingsModel = { keyField: 'actionTypeId', textField: 'actionTypeText' };

  public dialogSettings: DialogSettingsModel = {
    fields: [
        { key: 'StatusText', type: 'DropDown' },
        { key: 'Summary', type: 'TextArea' }
    ]
} as DialogSettingsModel;

  constructor() {
    // this.data = _listService;
  }

  ngOnInit(): void {
    let state = { skip: 0, take: 10 };
    // this._listService.execute(this.branchId, state);

    const composedURL = `${this._http.GET_API_URL()}cac/action/list/${this.parentEmbeddedAppTypeId}`;
    this.dataManager = new DataManager({
      url: composedURL,
      headers: [{ 'Authorization': `Bearer ${this._itmUserService.getAccessTokenLocalStorage()}` }],
      adaptor: new UrlAdaptor()
    });
  }

  ngOnDestroy(): void {
  }

  dragRestrictedColumns: any[] = [
    this.ActionStatus.DoNotEscalate.toString()
    , this.ActionStatus.Resolved.toString()
    , this.ActionStatus.Escalated.toString()
    , this.ActionStatus.Reminder.toString()
    , this.ActionStatus.Resolved.toString()
  ];
  onDrag(args: DragEventArgs): void {
    // Disable drag if the item is in a restricted column
    const targetColumn = args.data[0].status;
    if(args.data && args.data.length>0 && this.dragRestrictedColumns.includes(targetColumn.toString())){
      args.cancel = true;
    }
  }

  dropRestrictedColumns: any[] = [
    this.ActionStatus.New.toString()
    , this.ActionStatus.Resolved.toString()
    , this.ActionStatus.Escalated.toString()
    // , this.ActionStatus.Reminder.toString()
    , this.ActionStatus.Resolved.toString()
  ];
  onActionBegin(args: ActionEventArgs): void {
    // Disable drop if the target column is restricted
    if(args.requestType == 'cardChange'){
      if(args.changedRecords && args.changedRecords.length > 0){
        const draggedItem = args.changedRecords[0];
        if(this.dropRestrictedColumns.includes(draggedItem.status)){
          args.cancel = true;
        }
      }
    }
  }

  onActionComplete(args: any): void {
    if (args.requestType === 'cardChanged') {
      if(args.changedRecords && args.changedRecords.length > 0){
        const draggedItem = args.changedRecords[0];
        if(draggedItem.status == this.ActionStatus.Reminder){
          this._cacService.remind([draggedItem.id]).subscribe((resp: any) => {
          })
        }
        else if(draggedItem.status == this.ActionStatus.DoNotEscalate){
          const data = [{
            'id': draggedItem.id,
            'reason': 'Kanban - Drag and Drop'
          }];
          this._cacService.doNotEscalate(data).subscribe((resp: any) => {
          })
        }
      }
    }
  }

  disableCardDoubleClick(event: any): void {
    // console.log('Double-click on Kanban card has been disabled.', event);
    event.cancel = true;
  }

  onCardClick(event: any) {
    const selectedCardData = event;
    // console.log('Selected Card Data:', selectedCardData);
  }

  onClickAction(data: any){
    // console.log(data)
    if(data){
      data.actionId = data.id;
      this._cacNotifyService.sendOpenSidebar(JSON.stringify(data));
    }
  }

  getSelectedCard() {
    const selectedCards = this.kanbanctrl.getSelectedCards();
    // console.log(selectedCards)
    // if (selectedCards.length > 0) {
    //   const selectedCardData = selectedCards[0].data;
    //   console.log('Selected Card:', selectedCardData);
    // } else {
    //   console.log('No card is selected');
    // }
  }
  // public dataStateChange(state: DataStateChangeEventArgs): void {
  //   this._listService.execute(this.branchId, state);
  // }

  // public dataSourceChanged(state: DataSourceChangedEventArgs): void {
  //   if (state.requestType === 'cardCreated') { state.endEdit() }
  //   else if (state.requestType === 'cardChanged') { state.endEdit() }
  //   else if (state.requestType === 'cardRemoved') { state.endEdit() }
  // }
}
