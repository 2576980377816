{
    "latitude": -24.94589543438734,
    "longitude": 135.25245658936578,
    "zoom": 16,
    "view": "Auto",
    "showLogo": false,
    "renderWorldCopies": true,
    "language": "en-AU",
    "enableAccessibility": true,
    "autoResize": true,
    "touchInteraction": true,
    "preserveDrawingBuffer": true,
    "zoomDelta": 2,
    "pitchDegreesDelta": 2,
    "rotationDegreesDelta": 2,
    "fullScreenStyle": "auto",
    "mapStyles": [ "road", "satellite", "satellite_road_labels" ]
}
