<div class="w-full itm-page-layout">
    <div class="w-full">
        <div class="flex flex-row">
            <div class="basis-11/12 text-lg font-bold uppercase">{{this.actionData()?.actionTitle}}</div>
            <div class="basis-1/12 text-right">
                <span class="e-icons e-large e-close cursor-pointer" (click)="closeClick()"></span>
            </div>
        </div>
        <div class="my-2 border border-solid bg-gray-500"></div>
        <div class="flex flex-row">
            @if(this.actionData() != undefined){
                @if(this.actionData().actionTypeId == ActionType.Alert){
                    <alert-sidebar #sidebar (fireReminderHourEvent)="onRemindHourClick($event)" 
                        (fireDoNotEscalateEvent)="onDoNotEscalateClick($event)"
                        [actionData]="actionData()">
                    </alert-sidebar>
                }
                @else if(this.actionData().actionTypeId == ActionType.Report){
                    <!-- <Report></Report> -->
                }
                @else if(this.actionData().actionTypeId == ActionType.OffHireRequest){
                    <!-- <OffHireRequest></OffHireRequest> -->
                }
                @else if(this.actionData().actionTypeId == ActionType.OnHireRequest){
                    <!-- <OnHireRequest></OnHireRequest> -->
                }
            }
        </div>
    </div>
</div>
