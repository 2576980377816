import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ERROR_MESSAGE, HTTP_ERROR, SERVER_CODE } from '@itm/constants';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  formatError(err: HttpErrorResponse): string {
    return this.httpErrorFormatter(err);
  }

  private httpErrorFormatter(err: HttpErrorResponse): string {
    // In a real world app, we may send the error to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(err);
    console.error(err.error);
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `${HTTP_ERROR} ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // errorMessage = `${SERVER_CODE} ${err.status}, ${ERROR_MESSAGE} ${err.error.errors.map(x => x.detail).join(', ')}`;
      errorMessage = `${ERROR_MESSAGE} ${err.error.errors.map(x => x.detail).join(', ')}`;
    }
    return errorMessage;
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // errorMessage = `Server returned code: ${err.status}, error message is: ${err.message
      errorMessage = `Error message is: ${err.message
        }`;
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }
}
