import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ItmToastrService {
  #toastr = inject(ToastrService);

  showSuccess(message: string): void {
    this.#toastr.success(message, '', {
      timeOut: 5000,
      progressBar: false
    });
  }
  showWarning(message: string): void {
    this.#toastr.warning(message, '', {
      timeOut: 5000,
      progressBar: false
    });
  }
  showInfo(message: string): void {
    this.#toastr.info(message, '', {
      timeOut: 5000,
      progressBar: false
    });
  }
  showError(message: string, title: string = ''): void {
    this.#toastr.error(message, title, {
      timeOut: 5000,
    });
  }
}
