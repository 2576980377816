import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppEmbeddedService {

  constructor() {
  }

  private _appEmbeddedType = new BehaviorSubject<any>(null);
  public appEmbeddedType$ = this._appEmbeddedType.asObservable();
  setAppEmbeddedType(parms: any) {
      this._appEmbeddedType.next(parms);
  }

}
