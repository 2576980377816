import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withComponentInputBinding, withDisabledInitialNavigation, withEnabledBlockingInitialNavigation, withInMemoryScrolling, withPreloading } from '@angular/router';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, BrowserUtils, Configuration, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/environment';
import { provideToastr } from 'ngx-toastr';
import { AuthInterceptor } from './auth.interceptor';

//==============================V3
export function loggerCallback(logLevel: LogLevel, message: string) {
    // console.log('loggerCallback',logLevel,message);
}

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
const msalConfig: Configuration = {
    auth: {
        clientId: environment.AZURE_APPLICATION_ID,
        authority: environment.AZURE_AUTHORITY,
        // // knownAuthorities: [environment.b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: environment.webRootUrl, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                if (logLevel == LogLevel.Error || logLevel == LogLevel.Warning) {
                }
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}
const silentRequest = {
    scopes: ["openid", "profile", "email"],
    loginHint: "example@domain.net"
};
const protectedResources = {
    allApi: {
        endpoint: environment.apiUrl,
        scopes: [environment.allApi_scopes],
    },
}
const loginRequest = {
    // scopes: ["openid", "profile", "email"]
    scopes: ['user.read']
};

export function MSALInstanceFactory(): IPublicClientApplication {
    ////msal-sl-2
    // console.log('MSALInstanceFactory', msalConfig);
    
    return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    ////msal-sl-3
    // console.log('MSALInterceptorConfigFactory');
    
    const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set(`${environment.apiUrl}`, [environment.allApi_scopes]);
    protectedResourceMap.set(environment.GRAPH_ENDPOINT, [environment.allApi_scopes]);
    protectedResourceMap.set(`${environment.rootUrl}`, [environment.AZURE_APP_API_PERMISSION]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    ////msal-sl-1
    // console.log('MSALGuardConfigFactory');
    
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest,
        loginFailedRoute:''
    };
}

const initialNavigation = !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
    ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
    : withDisabledInitialNavigation();
//V3

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideToastr({
            timeOut: 1500,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            tapToDismiss: true,
            newestOnTop:true,
            progressBar:true,
            progressAnimation:'increasing',
        }), // Toastr providers from 'ngx-toastr' >> https://www.npmjs.com/package/ngx-toastr
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
            withComponentInputBinding(),
        ),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,

        // Fuse
        //provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'compact',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-connect1',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                    {
                        id: 'theme-connect1',
                        name: 'Connect (CAC)',
                    }
                ],
            },
        }),
    ],
};
