import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DynamicFormService } from '@itm/services';

@Component({
  selector: 'itm-breadcrumbs',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [CommonModule, MatIconModule],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss'
})
export class BreadcrumbsComponent {
  private readonly _dfs = inject(DynamicFormService);
  breadcrumb = input<string[]>();
  editTitle = this._dfs.editTitle;

  firstItem = computed(() => this.breadcrumb()[0]);
  lastItem = computed(() => {
    const breadcrumb = this.breadcrumb();
    if (breadcrumb.length > 1) {
      return breadcrumb[breadcrumb.length - 1];
    }    
  });
}
