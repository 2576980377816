import { Injectable } from '@angular/core';
import jsonata from "jsonata";
@Injectable({
  providedIn: 'root'
})
export class JsonataTransformService {

  constructor() { }
  /**
   * @description transforms a json using jsonata library
   * @param expression JSONATA transform expression
   * @param data data to be transformed
   * @returns transformed data based on expression
   */
  transformData = async (expression: string, data: any) => {
    try {
      const result = await jsonata(expression).evaluate(data);
      return result;
    } catch (error) {
      console.error('Error evaluating expression:', error);
      throw error;
    }
  };
}
