import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ItmNavigationService {

    routerSvc = inject(Router);

    navigateAndSetReturnUrl(url: string, returnUrl: string) {
        var key = url.replace(/\//g, '').replace(/-/g, '');
        sessionStorage.setItem(key, returnUrl);
        this.routerSvc.navigate([url]);
    }

    navigateAndUpdateReturnUrl(url: string, currentUrl: string) {
        var key = currentUrl.replace(/\//g, '').replace(/-/g, '');
        var returnUrl = sessionStorage.getItem(key);

        key = url.replace(/\//g, '').replace(/-/g, '');

        if (returnUrl == null || returnUrl == undefined || returnUrl.trim() == '') {
            returnUrl = '/';
        }


        sessionStorage.setItem(key, returnUrl);
        this.routerSvc.navigate([url]);
    }

    navigateToReturnurl(currentUrl: string) {

        var key = currentUrl.replace(/\//g, '').replace(/-/g, '');

        var returnUrl = sessionStorage.getItem(key);
        if (returnUrl == null || returnUrl == undefined || returnUrl.trim() == '') {
            returnUrl = '/';
        }

        sessionStorage.removeItem(key);
        sessionStorage.setItem('lastNavigationKey', key);
        sessionStorage.setItem('lastNavigationUrl', returnUrl);

        this.routerSvc.navigate([returnUrl]);
    }

    navigate(url: string) {
        this.routerSvc.navigate([url]);
    }

    navigateToNewTab(url: string) {
        window.open(url, '_blank');
    }
}
