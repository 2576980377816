import { Injectable, effect, inject, signal } from '@angular/core';
import { ApiHttpService } from './api-http.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, filter, map, Observable, switchMap, tap } from 'rxjs';
import { ILayoutConfig, LayoutConfig } from '../types/layout.types';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LAYOUT_ENDPOINT } from '@itm/constants';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private readonly _http = inject(ApiHttpService);
  private readonly url_layout_endpoint: string = LAYOUT_ENDPOINT;

  constructor() {
  }

  CurrentActivatedRouteSig = signal<string | undefined>(undefined);
  setCurrentActivatedRoute(url: string) {
    this.CurrentActivatedRouteSig.set(url);
  }
  currentActivatedRoute$ = toObservable(this.CurrentActivatedRouteSig);

  layoutConfig$ = this.currentActivatedRoute$
    .pipe(
      filter(Boolean),
      switchMap(x => this._http.GETT<ILayoutConfig>(`${this.url_layout_endpoint}/${x}`)
        .pipe(
          map(x => x.data.data),
          tap(x => this.LayoutConfigSig.set(x))
        ))
    );
  // LayoutConfigSig = toSignal(this.layoutConfig$, { initialValue: undefined });
  LayoutConfigSig = signal<ILayoutConfig>(undefined);

  // // private gridData$ = this.layoutConfig$
  // //   .pipe(
  // //     tap(x => console.log('gridData$ =>', x)),
  // //     filter(Boolean),
  // //     filter(x => x.pageType === 'List'),
  // //     switchMap(x => this.#httpClient.POST(x.listEndpoint)
  // //       .pipe(
  // //         tap(x => console.log('listEndpoint$ =>', x.data)),
  // //         map(x => x.data)
  // //       ))
  // //   );

  getLayoutConfig(x: any) : Observable<any>{
    return this._http.GETT<ILayoutConfig>(`${this.url_layout_endpoint}/${x}`);
  }

  // // GridDataSig = toSignal(this.gridData$, { initialValue: [] });
  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

  ///----------------------------
  ///Shared Data Store for Layout
  ///----------------------------
  private dataStoreSubject = new BehaviorSubject<{ [key: string]: any }>({});
  //TODO rename to formdata
  dataStore$ = this.dataStoreSubject.asObservable();


  dataStoreSetItem(key: string, value: any): void {
    const currentDict = this.dataStoreSubject.getValue();
    currentDict[key] = value;
    // console.log(key,value);
    if (currentDict) {
      this.dataStoreSubject.next(currentDict);
    }
  }

  dataStoreGetItem(key: string): any {
    return this.dataStoreSubject.getValue()[key];
  }

  dataStoreRemoveItem(key: string): void {
    const currentDict = this.dataStoreSubject.getValue();
    delete currentDict[key];
    this.dataStoreSubject.next(currentDict);
  }

  dataStoreClear(): void {
    this.dataStoreSubject.next({});
  }

  /**
   * Form open mode decouple implementation
   */
  private _formOpenMode = new BehaviorSubject<'full' | 'slider'>('full');
  formOpenMode$ = this._formOpenMode.asObservable();
  setOpenMode(mode: 'full' | 'slider') {
    this._formOpenMode.next(mode);
  }

  private _toolbarButtonClick = new BehaviorSubject<any>(null);
  toolbarButtonClick$ = this._toolbarButtonClick.asObservable();
  setToolbarButtonClick(btn: any) {
    this._toolbarButtonClick.next(btn);
  }

  private _toolbarSwitchClick = new BehaviorSubject<any>(null);
  toolbarSwitchClick$ = this._toolbarSwitchClick.asObservable();
  setToolbarSwitchClick(btn: any) {
    this._toolbarSwitchClick.next(btn);
  }

  private _toolbarFormButtonClick = new BehaviorSubject<any>(null);
  public toolbarFormButtonClick$ = this._toolbarFormButtonClick.asObservable();
  setToolbarFormButtonClick(btn: any) {
    this._toolbarFormButtonClick.next(btn);
  }

}
