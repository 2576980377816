import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacNotifyService {
  constructor() {
  }

  private _sidebarOpenedStatus = new BehaviorSubject<any>(null);
  sidebarOpenedStatus$ = this._sidebarOpenedStatus.asObservable();
  setSidebarOpenedStatus(isOpened: any) {
      this._sidebarOpenedStatus.next(isOpened);
  }


  private _receiveNotification = new BehaviorSubject<any>(null);
  receiveNotification$ = this._receiveNotification.asObservable();
  receiveNotification(data: any) {
      this._receiveNotification.next(data);
  }

  private _sendOpenSidebar = new BehaviorSubject<any>(null);
  sendOpenSidebar$ = this._sendOpenSidebar.asObservable();
  sendOpenSidebar(data: any) {
      this._sendOpenSidebar.next(data);
  }

}
