import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, EventEmitter, inject, input, OnDestroy, OnInit, Output } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatInputModule } from '@angular/material/input';
import { RouterOutlet } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { ItmToolbarComponent } from '@itm/components/toolbar';
import { 
  GridModule, SearchService, ToolbarService, ExcelExportService, PdfExportService, FilterService, SortService, GroupService, PageService
  , ColumnChooserService, ColumnMenuService, ReorderService, ResizeService
} from '@syncfusion/ej2-angular-grids';
import { AlertSidebarComponent } from './alert/alert-sidebar.component';
import { ActionAlertSubType, ActionType, IActions } from '../../utilities';
import { filter, map, Subject, takeUntil } from 'rxjs';
import { CacActionService } from '../../cac.action.service';
import { ItmToastrService } from '@itm/services';

@Component({
    selector: 'sidebar-manager',
    templateUrl: './sidebar-manager.component.html',
    styleUrls: ['./sidebar-manager.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterOutlet, CommonModule, GridModule, ItmToolbarComponent, FuseAlertComponent, MatInputModule, AlertSidebarComponent],
    providers: [SearchService,
      ToolbarService,
      ExcelExportService,
      PdfExportService,
      FilterService,
      SortService,
      GroupService,
      PageService,
      ColumnChooserService,
      ColumnMenuService,
      ReorderService,
      ResizeService
    ],
})
export class SideBarManagerComponent implements OnInit, OnDestroy {

  private readonly _cacService = inject(CacActionService);
  private readonly _toastr = inject(ItmToastrService);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  @Output() firePopupCloseEvent = new EventEmitter();

  ActionType = ActionType;
  title: any = '';

  actionData = input<any>();
  actionData$ = toObservable(this.actionData);

  // action$ = this.actionData$.pipe(
  //   takeUntil(this._unsubscribeAll),
  //   filter(Boolean),
  //   map((t: IActions) => {
  //     this.setTitle(t);
  //     this._changeDetectorRef.markForCheck();
  //   })
  // );
  // actionSig = toSignal(this.action$);
  
  constructor() {
    effect(() => {
      // console.log(this.actionData())
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  closeClick(){
    this.firePopupCloseEvent.emit();
  }

  onRemindHourClick(e: any){
    this._cacService.remind([this.actionData()?.id]).subscribe((resp: any) => {
      if(resp.data.result == true){
        this.firePopupCloseEvent.emit();
        this._toastr.showSuccess('Successfully applied reminder.');
      }
    })
  }

  onDoNotEscalateClick(e: any){
    const data = [{
      'id': this.actionData()?.id,
      'reason': e.data.reason
    }];
    this._cacService.doNotEscalate(data).subscribe((resp: any) => {
      if(resp.data.result == true){
        this.firePopupCloseEvent.emit();
        this._toastr.showSuccess('Successfully applied do not escalate.');
      }
    })
  }

  // setTitle(data: IActions){
  //   if(!data) return;

  //   if(data.actionTypeId == ActionType.Alert){
  //     if(data.actionSubTypeId == ActionAlertSubType.OutSideNotHire){
  //       this.title = `Equipment outside branch and not on hire`;
  //     }
  //     else if(data.actionSubTypeId == ActionAlertSubType.OutSideStoodDownUsed){
  //       this.title = `Equipment outside of branch, stood down, but being used`;
  //     }
  //     else if(data.actionSubTypeId == ActionAlertSubType.OutSideOffHiredUsed){
  //       this.title = `Equipment is outside branch, off-hired but being used`;
  //     }
  //     else if(data.actionSubTypeId == ActionAlertSubType.InSideOnHire){
  //       this.title = `Equipment is in the branch, but on hire`;
  //     }
  //   }
  //   console.log(this.title)
  // }


}
