import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { EMPTY, map, Observable, pipe, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { ApiHttpService } from '@itm/services';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    listApiUrl: any = 'cac/notification/list';
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<
        Notification[]
    >(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _apiHttpService: ApiHttpService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<Notification[]> {
        return this._apiHttpService.POST<Notification[]>(this.listApiUrl, {})
        // return this._httpClient
        //     .get<Notification[]>('api/common/notifications')
            .pipe(
                map(x => {
                    return x.data['result']
                }),
                tap((notifications: Notification[]) => {
                    this._notifications.next(notifications);
                })
            );
    }

    addToList(newNotification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            map((notifications) => {
                this._notifications.next([
                    newNotification,
                    ...notifications,
                ]);
                return newNotification;
            })
        );

        // return this.notifications$.pipe(
        //     take(1),
        //     switchMap((notifications) => 
        //         notifications.map((notification) => {
        //             console.log(notification)
        //             this._notifications.next([
        //                 newNotification,
        //                 ...notifications,
        //             ]);
        //             return newNotification;
        //         })
        //     )
        // )
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient
                    .post<Notification>('api/common/notifications', {
                        notification,
                    })
                    .pipe(
                        map((newNotification) => {
                            // Update the notifications with the new notification
                            this._notifications.next([
                                ...notifications,
                                newNotification,
                            ]);

                            // Return the new notification from observable
                            return newNotification;
                        })
                    )
            )
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    markReadUnRead(id: string, notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                // this._httpClient
                //     .patch<Notification>('api/common/notifications', {
                //         id,
                //         notification,
                //     })
                this._apiHttpService.POST<Notification>(`cac/notification/${id}/read`, {
                    isRead: notification.isRead,
                    readByUser: 'logged in user'
                })
                .pipe(
                    map((updatedNotification: any) => {
                        return updatedNotification.data['result'];
                    }),
                    tap((updatedNotification: any) => {
                        // console.log(updatedNotification)
                        // Find the index of the updated notification
                        const index = notifications.findIndex(
                            (item) => item.id === id
                        );

                        // Update the notification
                        notifications[index] = updatedNotification;

                        // Update the notifications
                        this._notifications.next(notifications);

                        // Return the updated notification
                        return updatedNotification;
                    })
                )
            )
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                // this._httpClient
                //     .delete<boolean>('api/common/notifications', {
                //         params: { id },
                //     })                
                this._apiHttpService.POST<Notification>(`cac/notification/${id}/remove`, {})
                .pipe(
                    map((updatedNotification: any) => {
                        return updatedNotification.data['result'];
                    }),
                    tap((notification: any) => {
                        // Find the index of the deleted notification
                        const index = notifications.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the notification
                        notifications.splice(index, 1);

                        // Update the notifications
                        this._notifications.next(notifications);

                        // Return the deleted status
                        return notification;
                    })
                )
            )
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient
                    .get<boolean>('api/common/notifications/mark-all-as-read')
                    .pipe(
                        map((isUpdated: boolean) => {
                            // Go through all notifications and set them as read
                            notifications.forEach((notification, index) => {
                                notifications[index].isRead = true;
                            });

                            // Update the notifications
                            this._notifications.next(notifications);

                            // Return the updated status
                            return isUpdated;
                        })
                    )
            )
        );
    }
}
