@if (toolbarType() === TOOL_BAR_TYPE_FORM && buttons()?.length>0) {
<div class="itm-toolbar itm-tbar-wrapper ">
    <ejs-toolbar class="itm-tbar shadow bg-card form-toolbar" [overflowMode]="overflowMode()" (clicked)="toolbarFormClick($event)">
        <e-items>
            <e-item align="left" [htmlAttributes]='itmToolbarAction'>
                <ng-template #template>
                    <itm-breadcrumbs class="itm-breadcrumbs" [breadcrumb]="breadcrumb()" [editTitle]="editTitle()"></itm-breadcrumbs>
                </ng-template>
            </e-item>

            @for (item of buttonsSorted(); track $index) {
            @if (item.type == TOOL_BAR_ITEM_TYPE_BUTTON) {
            <e-item type="Button" [htmlAttributes]='itmToolbarAction' [text]="item.caption" [align]="item.align"
                [prefixIcon]="item.prefixIcon" [cssClass]="item.cssClass"></e-item>
            }
            @if (item.type == TOOL_BAR_ITEM_TYPE_SEPARATOR) {
            <e-item type="Separator" [htmlAttributes]='itmToolbarAction'></e-item>
            }
            @if (item.type == TOOL_BAR_ITEM_TYPE_DROPDOWN_BUTTON) {
            <e-item [align]="item.align" [htmlAttributes]='itmToolbarAction'>
                <ng-template #template>
                    <div>
                        <!-- <button ejs-dropdownbutton [items]="this.items1" (select)="test($event)"
                            [content]="item.caption" [iconCss]="item.prefixIcon" cssClass="e-caret-hide"></button> -->
                        <button ejs-dropdownbutton [items]="item.options" (select)="onExportClick($event)"
                            [content]="item.caption" [prefixIcon]="item.prefixIcon" [iconCss]="item.prefixIcon"
                            cssClass="e-caret-hide"></button>
                    </div>
                </ng-template>
            </e-item>
            }
            }
        </e-items>
    </ejs-toolbar>
</div>
}
@else if (toolbarType() === TOOL_BAR_TYPE_LIST && buttons()?.length>0) {
<div class="itm-toolbar itm-tbar-wrapper">
    <ejs-toolbar class="itm-tbar shadow bg-card list-toolbar" [overflowMode]="overflowMode()" (clicked)="toolbarClick($event)">
        <e-items>
            <e-item align="left" [htmlAttributes]='itmToolbarAction'>
                <ng-template #template>
                    <itm-breadcrumbs [breadcrumb]="breadcrumb()"></itm-breadcrumbs>
                </ng-template>
            </e-item>

            @for (item of buttonsSorted(); track $index) {
                @if (item.type == TOOL_BAR_ITEM_TYPE_BUTTON) {
                <e-item type="Button" [htmlAttributes]='itmToolbarAction' [id]="item.name" [text]="item.caption" [align]="item.align"
                    [visible]="item.visible" [prefixIcon]="item.prefixIcon" [cssClass]="item.cssClass"></e-item>
                }
                @else if (item.type == TOOL_BAR_ITEM_TYPE_SWITCH) {
                    <e-item [align]="item.align" [htmlAttributes]='itmToolbarAction' [visible]="item.visible">
                        <ng-template #template>
                            <!-- <label for='switch3' style="padding: 10px 10px 10px 7px"> List View </label> -->
                            <ejs-switch #switch [id]="item.name" label="Toggle Switch" onLabel="ON" offLabel="OFF" [checked]="switchState" 
                            [value]= "item.name" (change)="onSwitchChange(item.name, $event)"></ejs-switch>
                        </ng-template>
                    </e-item>
                }
                @else if (item.type == TOOL_BAR_ITEM_TYPE_SEPARATOR) {
                <e-item type="Separator" [htmlAttributes]='itmToolbarAction'></e-item>
                }
                @else if (item.type == TOOL_BAR_ITEM_TYPE_DROPDOWN_BUTTON) {
                <e-item [align]="item.align" [htmlAttributes]='itmToolbarAction' [visible]="item.visible">
                    <ng-template #template>
                        <div>
                            <button ejs-dropdownbutton [items]="item.options" (select)="onExportClick($event)"
                                [content]="item.caption" [prefixIcon]="item.prefixIcon" [iconCss]="item.prefixIcon"
                                cssClass="e-caret-hide"></button>
                        </div>
                    </ng-template>
                </e-item>
                }
            }
        </e-items>
    </ejs-toolbar>
</div>
}