import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, OnDestroy, OnInit, signal } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterOutlet } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { ItmToolbarComponent } from '@itm/components/toolbar';
import { ILayoutConfig } from '@itm/services';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { 
  GridModule, SearchService, ToolbarService, ExcelExportService, PdfExportService, FilterService, SortService, GroupService, PageService
  , ColumnChooserService, ColumnMenuService, ReorderService, ResizeService

} from '@syncfusion/ej2-angular-grids';
import { SidebarModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { SideBarManagerComponent } from '../popup/manager/sidebar-manager.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { ActionCentreKanbanComponent } from './action-centre-kanban.component';
import { FuseConfigService } from '@fuse/services/config';
import { ActionCentreListComponent } from './action-centre-list.component';

@Component({
    selector: 'public-action-centre-list',
    templateUrl: './public-action-centre-list.component.html',
    styleUrls: ['./action-centre-list.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.Default,
    imports: [RouterOutlet, CommonModule, GridModule, ItmToolbarComponent, FuseAlertComponent, MatInputModule, FuseDrawerComponent
      , DropDownListModule, SidebarModule, SideBarManagerComponent, ActionCentreKanbanComponent, TabModule
      , ActionCentreListComponent],
    providers: [SearchService,
      ToolbarService,
      ExcelExportService,
      PdfExportService,
      FilterService,
      SortService,
      GroupService,
      PageService,
      ColumnChooserService,
      ColumnMenuService,
      ReorderService,
      ResizeService
    ],
})
export class PublicActionCentreListComponent implements OnInit, OnDestroy {
  
  constructor(private router: Router, private _fuseConfigService: FuseConfigService) {
    // console.log('PublicActionCentreListComponent constructor');
    effect(() => {
      // console.log('listColumns', this.listColumns());
    });
  }

  layoutConfig = signal<ILayoutConfig>(undefined);

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
  
}
