import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { LayoutService, ILayoutConfig } from '@itm/services';
import { AuthService } from 'app/core/auth/auth.service';

export const LayoutResolver: ResolveFn<ILayoutConfig> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const embeddedAppType = {
        All: 0,
        Connect: 1,
        OnSiteLive: 2,
    }
    const parms = route.queryParams;
    if([embeddedAppType.Connect.toString(), embeddedAppType.OnSiteLive.toString()].includes(parms.parent) 
        && parms.layout == 'empty'){
        const authService = inject(AuthService);
        if(parms.accessToken){
            authService.accessToken = parms.accessToken;
        }
    }

    const _layoutService = inject(LayoutService);
    const pageKey:string = route.data['pageKey'];
    _layoutService.setCurrentActivatedRoute(pageKey);
    _layoutService.setToolbarButtonClick(null);
    _layoutService.setToolbarFormButtonClick(null);
    return _layoutService.layoutConfig$;
};